<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
  <a-form class="mt3 import-customer-form"
          :model="importForm"
          :wrapperCol="{span: 8}"
          :colon="false">
    <a-form-item>
      <span class="gray-darker mr2">点击下载</span>
      <a href="https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/%E5%AE%A2%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx">Excel模板</a>
    </a-form-item>

    <a-form-item class="customer-import-select-excel"
                 label="选择表格">
      <template v-if="helpText[0]"
                #help>
        <span class="color-danger">{{helpText[0]}}</span>
      </template>
      <upload-excel @uploadError="handleUploadHelp"
                    @uploaded="handleExcelUploaded"></upload-excel>
    </a-form-item>

    <a-form-item label="分配员工">
      <template v-if="helpText[1]"
                #help>
        <span class="color-danger">{{helpText[1]}}</span>
      </template>

      <template #extra>
        <span class="form-prompt">* 上传的客户将平均分配给选择的员工，分配完成后员工会收到提醒。</span>
      </template>

      <div class="ant-input-affix-wrapper flex justify-between"
           @click.stop="selectStaffModalVisible = true">
        <span v-if="staffList.length === 0"
              style="color: #bfbfbf;">请选择员工</span>
        <div class="overflow-hidden"
             style="max-width: 312px; max-height: 24px;">
          <template v-for="(staff, index) in staffList"
                    :key="index">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>
            <span v-if="index !== staffList.length - 1">, </span>
          </template>
        </div>

        <span v-if="staffNum > 0">等{{staffNum}}人</span>
      </div>

      <select-staff-by-group v-model:visible="selectStaffModalVisible"
                             @saveStaff="saveStaff"
                             :defaultCheckedKeys="checkedKeys">
      </select-staff-by-group>
    </a-form-item>

    <a-form-item label="客户标签"
                 ref="tagList"
                 name="tagList">
      <template #help>
        <span class="form-prompt">* 添加成功的客户会被自动添加选择的标签。</span>
      </template>

      <a-select v-model:value="importForm.corpTagIds"
                :showSearch="false"
                mode="multiple"
                placeholder="请选择标签"
                showArrow>
        <a-select-opt-group v-for="corpTagGroup in corpTagList"
                            :key="corpTagGroup.workWechatThirdAppTagGroupId"
                            :label="corpTagGroup.name">
          <template v-if="corpTagGroup.workWechatThirdAppTagGroupId">
            <a-select-option v-for="tag in corpTagGroup.corpTagList"
                             :key="tag.workWechatThirdAppTagId">
              <template v-if="tag.workWechatThirdAppTagId">
                {{tag.name}}
              </template>
            </a-select-option>
          </template>
        </a-select-opt-group>
      </a-select>
    </a-form-item>

    <div class="submit-btn">
      <a-button class=""
                type="primary"
                @click="onSubmit"
                :loading="btnLoading">
        导入
      </a-button>
    </div>
  </a-form>

  <a-modal v-model:visible="modalVisible"
           title="提醒"
           width="600px"
           @ok="importExcel"
           okText="导入">
    <div class="import-customer-modal"
         v-html="errMessage"></div>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import _ from "lodash";
import axios from "axios";

import RouterHeader from "@/components/RouterHeader.vue";
import UploadExcel from "@/views/customerImport/UploadExcel";
import SelectStaffByGroup from "@/components/SelectStaffByGroup";

import { apiStore } from "@/shared/service/api-client";
import CorpTagApi from "@/service/api/corpLag.js";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ImportantCustomer",
  components: {
    RouterHeader,
    UploadExcel,
    SelectStaffByGroup,
  },
  setup() {
    const routerHeaderInfo = [
      {
        path: "/conduction/customerImport",
        name: "客户导入",
      },
      { name: "导入客户" },
    ];

    const importForm = reactive({});

    const selectStaffModalVisible = ref(false);
    const checkedKeys = ref([]);
    const staffList = ref([]);
    const staffNum = ref(undefined);
    function saveStaff(checkedStaffs) {
      checkedKeys.value = [];
      selectStaffModalVisible.value = false;

      // 截取并拼接staffs的id
      staffList.value = [];
      importForm.staffIds = [];
      _.forEach(checkedStaffs, (staff) => {
        staffList.value.push(staff.workWechatThirdAppUserId);
        checkedKeys.value.push(staff.staffId);
        const splitArr = _.split(staff.staffId, "_");
        importForm.staffIds.push(Number(splitArr[1]));
      });

      staffNum.value = checkedStaffs.length;

      helpText.value[1] = checkedStaffs.length ? "" : "请选择分配员工";
    }

    const corpTagList = ref();
    async function getTagList() {
      corpTagList.value = await CorpTagApi.getList();
    }

    function handleExcelUploaded(file) {
      importForm.file = file;
      helpText.value[0] = "";
    }

    const helpText = ref([]);
    function handleUploadHelp(text) {
      helpText.value[0] = text ? text : "";
    }

    const btnLoading = ref(false);
    const modalVisible = ref(false);
    const errMessage = ref("");
    async function onSubmit() {
      _.fill(helpText.value, "");
      if (!checkValidate()) {
        return;
      }

      checkImport();
    }

    function checkValidate() {
      let flag = true;
      if (!importForm.staffIds || !importForm.staffIds.length) {
        helpText.value[1] = "请选择分配员工";
        flag = false;
      }
      if (!importForm.file) {
        helpText.value[0] = "请上传表格";
        flag = false;
      }
      return flag;
    }
    function checkImport() {
      const formData = new FormData();
      formData.append("file", importForm.file);

      axios
        .post("/api/console/customerAllot/checkImport", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            Authorization: `Bearer ${apiStore.token}`,
          },
        })
        .then(() => {
          importExcel();
        })
        .catch((error) => {
          errMessage.value = error.response.data.message;
          modalVisible.value = true;
        });
    }

    const router = new useRouter();
    function importExcel() {
      if (!importForm.corpTagIds) {
        importForm.corpTagIds = "";
      }
      const formData = new FormData();
      _.forEach(importForm, (value, key) => {
        formData.append(key, value);
      });

      axios
        .post("/api/console/customerAllot/import", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            Authorization: `Bearer ${apiStore.token}`,
          },
        })
        .then(() => {
          message.success("导入成功");
          router.push({ name: "customerImport_index" });
        })
        .catch((error) => {
          message.error(error.response.data.message);
          modalVisible.value = false;
        });
    }

    onMounted(() => {
      getTagList();
    });

    return {
      routerHeaderInfo,

      importForm,

      selectStaffModalVisible,
      checkedKeys,
      staffList,
      staffNum,
      saveStaff,

      corpTagList,
      getTagList,

      handleExcelUploaded,

      helpText,
      handleUploadHelp,

      btnLoading,
      modalVisible,
      errMessage,
      onSubmit,
      importExcel,
    };
  },
});
</script>
<style lang='less' scoped>
.submit-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);

  .ant-btn {
    padding: 0 32px;
  }
}
:deep(.ant-form-item-label) {
  margin-right: 8px;
}
:deep(.ant-form-explain) {
  margin-top: 8px;
  margin-bottom: 8px;
}
:deep(.ant-form-extra) {
  margin-top: 8px;
}

.form-prompt {
  color: @gray;
  white-space: nowrap;
}

.import-customer-modal {
  max-height: 300px;
  line-height: 32px;
  overflow-y: auto;
  white-space: pre-line;
}

:deep(.ant-input-affix-wrapper) {
  &::before {
    display: none;
  }
}
</style>